"use client";

import * as React from "react";
import Link from "next/link";
import { Transition } from "@headlessui/react";

import { SubscriptionInfo } from "@/types";
import { useScrollBlock } from "@/lib/hooks/use-scroll-block";
import { searchLinks } from "@/lib/data/navigation";
import { ArrowRightIcon, XCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useUI } from "@/components/ui/context";
import Logo from "@/components/ui/logo";
import SearchInput from "@/components/search/search-input";
import SaleWrapper from "@/components/sale-wrapper";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import { ExaminePlusLink } from "@/components/cta";
import { Badge } from "@/components/ui/badge";
import EndsTonight from "@/components/ends-tonight";

interface SearchDialogProps extends React.HTMLAttributes<HTMLDivElement> {
	subscription?: SubscriptionInfo;
}

export default function SearchDialog({
	subscription,
	children,
}: SearchDialogProps): React.ReactElement<SearchDialogProps> {
	const { displaySearchDialog, openSearchDialog, closeSearchDialog } = useUI();
	const [blockScroll, allowScroll] = useScrollBlock();

	React.useEffect(() => {
		if (displaySearchDialog) {
			blockScroll();
		} else {
			allowScroll();
		}
	}, [allowScroll, blockScroll, displaySearchDialog]);

	React.useEffect(() => {
		const handleKeyDown = (event: { key: string }) => {
			if (event.key === "Escape" || event.key === "Esc") {
				closeSearchDialog();
			}
		};

		// Add event listener for keydown
		document.addEventListener("keydown", handleKeyDown);

		// Remove event listener on cleanup
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [closeSearchDialog]);

	return (
		<>
			{/* TRIGGER */}
			<Button
				className="p-0 hover:bg-transparent"
				variant="text"
				onClick={() => openSearchDialog()}
				aria-label="Open search dialog"
			>
				{children}
			</Button>

			{/* DIALOG */}
			<Transition
				show={displaySearchDialog}
				className="fixed inset-0 z-1001 h-screen origin-top overflow-y-auto bg-white px-5 md:px-7"
				id="search-dialog"
			>
				<div className="flex h-full flex-col overflow-y-auto bg-white">
					{/* Header */}
					<div className="sticky top-0 z-20 flex items-center justify-between bg-white py-4 wide:max-w-fixed">
						{/* logo */}
						<div className="flex items-center space-x-2">
							<Link href="/" onClick={() => closeSearchDialog()}>
								<Logo className="h-4 w-18 lg:h-8 lg:w-25 lg:fill-primary 2xl:w-36" />
							</Link>
							<SaleWrapper
								cta={
									<FreeTrialWrapper>
										<ExaminePlusLink
											onClick={() => {
												document.body.classList.remove("overflow-hidden");
											}}
											trackDetails={{
												trackFor: "trial",
												location: "mobile hamburger menu above search",
												label: "Examine logo <<Try Examine+ for free>>",
											}}
											className="lg:hidden"
										>
											<Badge variant="cyan" size="xs">
												Try Examine+ for free
											</Badge>
										</ExaminePlusLink>
									</FreeTrialWrapper>
								}
							>
								<ExaminePlusLink
									onClick={() => {
										document.body.classList.remove("overflow-hidden");
									}}
									trackDetails={{
										trackFor: "sale",
										location: "mobile hamburger menu above search",
										label: "Examine logo <<ON SALE>>",
									}}
								>
									<Badge variant="cyan" className="ml-1.5 space-x-1">
										<span>Examine+</span>
										<span>
											<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
												ON SALE
											</EndsTonight>
										</span>
									</Badge>
								</ExaminePlusLink>
							</SaleWrapper>
						</div>

						<div className="">
							<Button
								type="button"
								className="p-1"
								variant="text"
								onClick={() => closeSearchDialog()}
							>
								<XCircle className="h-6 w-6" color="#000" />
							</Button>
						</div>
					</div>

					{/* Search */}
					<div className="mx-auto w-full md:max-w-3xl">
						<SearchInput className="mt-0.5" home isMenu focusInputMobile={true} />
					</div>

					<section className="mx-auto w-full max-w-6xl py-4 lg:flex lg:space-x-20 xl:mt-5">
						<div>
							{searchLinks?.slice(0, 1).map((item, idx) => (
								<React.Fragment key={idx}>
									<div className="flex items-center justify-between gap-12 border-b border-dashed border-gray-400 py-2">
										<Link
											href={item.href}
											className="font-lora text-2xl font-semibold hover:text-primary"
											onClick={() => closeSearchDialog()}
										>
											{item.name}
										</Link>

										<Link
											href={item.href}
											className="lg:leading-1 inline-flex items-center gap-4 -tracking-2  text-primary hover:underline"
											onClick={() => closeSearchDialog()}
										>
											<span>View All</span>
											<span>
												<ArrowRightIcon className="h-[18px] w-[18px] rounded-full border border-primary stroke-[3px] p-[3px]" />
											</span>
										</Link>
									</div>

									<ul className="space-y-5 py-6 text-primary 2xl:text-lg">
										{item.children.map((link, idx) => (
											<li className="flex items-center gap-2.5" key={idx}>
												<Link
													href={link.href}
													className="hover:underline"
													onClick={() => closeSearchDialog()}
												>
													{link.name}
												</Link>
											</li>
										))}
									</ul>
								</React.Fragment>
							))}
						</div>

						<div className="grid flex-1 ">
							<div>
								{searchLinks?.slice(1, searchLinks.length).map((item, idx) => (
									<React.Fragment key={idx}>
										<div className="flex items-center justify-between gap-12 border-b border-dashed border-gray-400 py-2">
											<Link
												href={item.href}
												className="font-lora text-2xl font-semibold hover:text-primary"
												onClick={() => closeSearchDialog()}
											>
												{item.name}
											</Link>

											<Link
												href={item.href}
												className="lg:leading-1 inline-flex items-center space-x-4 -tracking-2  text-primary hover:underline"
												onClick={() => closeSearchDialog()}
											>
												<>
													<span>View All</span>
													<span>
														<ArrowRightIcon className="h-[18px] w-[18px] rounded-full border border-primary stroke-[3px] p-[3px]" />
													</span>
												</>
											</Link>
										</div>

										<ul className="space-y-5 py-6 text-primary sm:columns-2 2xl:text-lg">
											{item.children.map((link, idx) => (
												<li className="flex items-center gap-2.5" key={idx}>
													<Link
														href={link.href}
														className="hover:underline"
														onClick={() => closeSearchDialog()}
													>
														{link.name}
													</Link>
												</li>
											))}
										</ul>
									</React.Fragment>
								))}
							</div>
							<div>
								<div className="flex items-center justify-between border-b border-dashed border-gray-400 py-2">
									<h2 className="font-lora text-2xl font-semibold">Guides & Resources</h2>
								</div>

								<ul className="space-y-5 py-6 text-primary sm:columns-2 2xl:text-lg">
									<li className="flex items-center gap-2.5">
										<Link
											href="/guides/how-to-read-a-study/"
											target="_blank"
											className="hover:underline"
											onClick={() => closeSearchDialog()}
										>
											How to read a Study Guide
										</Link>
									</li>
									<li className="flex items-center gap-2.5">
										<Link
											href="/guides/protein-intake/"
											target="_blank"
											className="hover:underline"
											onClick={() => closeSearchDialog()}
										>
											Protein Intake Guide
										</Link>
									</li>
									<li className="flex items-center gap-2.5">
										<Link
											href="/nutrition/protein-intake-calculator/"
											target="_blank"
											className="hover:underline"
											onClick={() => closeSearchDialog()}
										>
											Optimal Protein Calculator
										</Link>
									</li>
									<li className="flex items-center gap-2.5">
										<Link
											href={
												subscription?.is_active
													? "/members/continuing-education/"
													: "/continuing-education/"
											}
											target="_blank"
											className="hover:underline"
											onClick={() => closeSearchDialog()}
										>
											Continuing Education Credits
										</Link>
									</li>
									<li className="flex items-center gap-2.5">
										<Link
											href="/about/no-brands/"
											target="_blank"
											className="hover:underline"
											onClick={() => closeSearchDialog()}
										>
											Why we don&apos;t recommend brands
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</section>
				</div>
			</Transition>
		</>
	);
}
