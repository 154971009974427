"use client";

import { useEffect, useState } from "react";
import Link from "next/link";

import { cn } from "@/lib/utils";
import { parseHTML, formatPageType } from "@/lib/utils/index";
import { ClientOnly } from "@/components/client-only";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useUI } from "@/components/ui/context";
import { Badge } from "@/components/ui/badge";

import { AccessCheck } from "@/components/access-check";

interface SearchAutoCompleteDropdownProps {
	data: any;
	searchTerm: string;
	showSuggestion: boolean;
	dropdownRef: React.MutableRefObject<any>;
	focusedIndex: number;
	setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
	handleSelection?: (item: any) => void;
}

export const SearchAutoCompleteDropdown = (props: SearchAutoCompleteDropdownProps) => {
	const { closeSearchDialog, closeMenu } = useUI();
	const { data, searchTerm, showSuggestion, dropdownRef, focusedIndex, setIsSearch } = props;

	const [isMobile, setIsMobile] = useState(
		typeof window !== "undefined" && window.innerWidth < 767 ? true : false
	);

	// Functions
	const textHighlight = (value: string) => {
		let reg = new RegExp(
			searchTerm?.toLowerCase().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
			"g"
		);
		let str = value.toLowerCase().replace(reg, "<strong>" + searchTerm.toLowerCase() + "</strong>");
		return parseHTML({ html: str });
	};

	const handleResize = () => {
		if (innerWidth < 767) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const handleCloseWithForward = () => {
		closeSearchDialog();
		closeMenu();

		if (setIsSearch) {
			document.body.classList.remove("overflow-hidden");
			setIsSearch(false);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (!showSuggestion) return null;

	return (
		<ClientOnly>
			{isMobile ? (
				<div ref={dropdownRef} className="w-full">
					<ul className="rounded-b border border-gray-200 bg-white py-2 text-left drop-shadow-sm md:pt-5">
						{data?.slice(0, window?.innerWidth <= 320 ? 2 : 3).map((item: any, i: number) => {
							const isFaq = item.page_type === "articles" && item.name.endsWith("?");
							const { overview, text } = formatPageType(isFaq ? "FAQs" : item.page_type);

							return (
								<li
									key={i}
									className={`${
										i + 1 === focusedIndex ? "text-stroke bg-examine-purple-100/60" : ""
									}`}
									onClick={() => handleCloseWithForward()}
								>
									<Link
										href={item.url}
										className="hover:text-stroke flex items-start justify-between px-4 py-2.5 text-primary first-letter:uppercase hover:bg-examine-purple-100/60 md:text-lg"
									>
										<span className="inline">{textHighlight(item.name)}</span>
										<Tooltip>
											<TooltipTrigger>
												<span className="view-page ml-1 inline whitespace-nowrap rounded-full border border-gray-200 bg-white px-2 py-1 font-sans text-[11px] font-medium leading-3 text-examine-green-500">
													{text}
												</span>
											</TooltipTrigger>
											<TooltipContent className="[@media(max-width:768px)]:hidden">
												<p>
													{parseHTML({
														html: overview,
													})}
												</p>
											</TooltipContent>
										</Tooltip>
									</Link>
								</li>
							);
						})}

						{data === null && (
							<li className="mt-2 block px-4 py-2.5 text-gray-600 lg:-mt-4">Not found</li>
						)}

						<AccessCheck
							feature={["examine_gpt"]}
							cta={
								<li
									className={cn(
										"border-t border-gray-200",
										data === null ? "pt-1" : "mt-1.5 pt-1.5"
									)}
									onClick={() => handleCloseWithForward()}
								>
									<Link
										href={`/examine-ai/?q=${encodeURIComponent(searchTerm)}`}
										className="hover:text-stroke flex items-start justify-between px-4 py-2.5 text-primary hover:bg-examine-purple-100/60 md:text-lg"
									>
										<span className="inline">
											Ask ExamineAI: <strong className="lowercase">{searchTerm}</strong>
										</span>
										<Badge
											variant="premium"
											className="ml-1 px-2 py-1 text-[11px] font-medium leading-3"
										>
											Examine+
											<span className="ml-1 hidden sm:inline">Feature</span>
										</Badge>
									</Link>
								</li>
							}
						/>
					</ul>
				</div>
			) : (
				<div ref={dropdownRef} className="absolute left-0 z-50 w-full">
					<ul className="rounded-b bg-white py-2 text-left drop-shadow-xl md:pt-5">
						{data?.slice(0, 7).map((item: any, i: number) => {
							const isFaq = item.page_type === "articles" && item.name.endsWith("?");
							const { overview, text } = formatPageType(isFaq ? "FAQs" : item.page_type);

							return (
								<li
									key={i}
									className={`${
										i + 1 === focusedIndex ? "text-stroke bg-examine-purple-100/60" : ""
									}`}
									onClick={() => handleCloseWithForward()}
								>
									<Link
										href={item.url}
										className="lg:hover:text-stroke flex items-center justify-between px-5 py-2 text-primary first-letter:uppercase md:text-lg lg:hover:bg-examine-purple-100/60"
									>
										<span className="inline">{textHighlight(item.name)}</span>
										<Tooltip>
											<TooltipTrigger>
												<span className="view-page ml-1 inline whitespace-nowrap rounded-full border border-gray-200 bg-white px-2 py-1 font-sans text-[11px] font-medium leading-3 text-examine-green-500">
													{text}
												</span>
											</TooltipTrigger>
											<TooltipContent className="[@media(max-width:768px)]:hidden">
												<p>
													{parseHTML({
														html: overview,
													})}
												</p>
											</TooltipContent>
										</Tooltip>
									</Link>
								</li>
							);
						})}

						{data === null && (
							<li className="mt-2 block px-5 py-2 text-lg text-gray-600 lg:-mt-4">Not found</li>
						)}

						<AccessCheck
							feature={["examine_gpt"]}
							cta={
								<li
									className={cn(
										"border-t border-gray-200",
										data === null ? "pt-1" : "mt-1.5 pt-1.5"
									)}
									onClick={() => handleCloseWithForward()}
								>
									<Link
										href={`/examine-ai/?q=${encodeURIComponent(searchTerm)}`}
										className="lg:hover:text-stroke flex items-center justify-between px-5 py-2 text-primary md:text-lg lg:hover:bg-examine-purple-100/60"
									>
										<span className="inline">
											Ask ExamineAI: <strong className="lowercase">{searchTerm}</strong>
										</span>
										<Badge
											variant="premium"
											className="ml-1 px-2 py-1 text-[11px] font-medium leading-3"
										>
											Examine+
											<span className="ml-1 hidden sm:inline">Feature</span>
										</Badge>
									</Link>
								</li>
							}
						/>
					</ul>
				</div>
			)}
		</ClientOnly>
	);
};
