export const navLinks = [
	{
		id: 1,
		href: "/research-feed/",
		title: "Research Feed",
		access: ["logged-out"],
	},
	{
		id: 2,
		href: "/guides/",
		title: "Guides",
		access: ["logged-out"],
	},
	{
		id: 3,
		title: "Health Topics",
		href: "/categories/",
		children: [
			{
				name: "Categories",
				href: "/categories/",
				children: [
					{
						href: "/categories/brain-health/",
						name: "Brain Health",
					},
					{
						href: "/categories/fat-loss/",
						name: "Fat Loss",
					},
					{
						href: "/categories/gut-health/",
						name: "Gut Health",
					},

					{
						href: "/categories/healthy-aging-longevity/",
						name: "Healthy Aging & Longevity",
					},
					{
						href: "/categories/muscle-gain-exercise/",
						name: "Muscle Gain & Exercise",
					},
					{
						href: "/categories/sleep/",
						name: "Sleep",
					},
					{
						href: "/categories/",
						name: "view-all",
						isIndex: true,
					},
				],
			},
			{
				name: "Conditions",
				href: "/conditions/",
				children: [
					{
						href: "/conditions/anxiety/",
						name: "Anxiety",
					},

					{
						href: "/conditions/alzheimers-disease/",
						name: "Alzheimer’s Disease",
					},
					{
						href: "/conditions/coronary-artery-disease/",
						name: "Coronary Artery Disease",
					},
					{
						href: "/conditions/depression/",
						name: "Depression",
					},
					{
						href: "/conditions/insomnia/",
						name: "Insomnia",
					},
					{
						href: "/conditions/multiple-sclerosis/",
						name: "Multiple Sclerosis",
					},
					{
						href: "/conditions/",
						name: "view-all",
						isIndex: true,
					},
				],
			},
			{
				name: "Outcomes",
				href: "/outcomes/",
				children: [
					{
						href: "/outcomes/blood-glucose/",
						name: "Blood Glucose",
					},
					{
						href: "/outcomes/blood-pressure/",
						name: "Blood Pressure",
					},
					{
						href: "/outcomes/nitric-oxide/",
						name: "Nitric Oxide",
					},
					{
						href: "/outcomes/testosterone/",
						name: "Testosterone",
					},
					{
						href: "/outcomes/",
						name: "view-all",
						isIndex: true,
					},
				],
			},
		],
		megamenu: true,
		access: ["logged-out", "free", "paid"],
	},
	{
		id: 4,
		title: "Supplements & More",
		href: "/interventions/",
		children: [
			{
				name: "Supplements",
				href: "/supplements/",
				children: [
					{
						href: "/supplements/ashwagandha/",
						name: "Ashwagandha",
					},
					{
						href: "/supplements/creatine/",
						name: "Creatine",
					},
					{
						href: "/supplements/curcumin/",
						name: "Curcumin",
					},
					{
						href: "/supplements/fish-oil/",
						name: "Fish Oil",
					},
					{
						href: "/supplements/vitamin-c/",
						name: "Vitamin C",
					},
					{
						href: "/supplements/vitamin-d/",
						name: "Vitamin D",
					},
					{
						href: "/supplements/",
						name: "More supplements",
						isIndex: true,
					},
				],
			},
			{
				name: "More Interventions",
				href: "/interventions/",
				children: [
					{
						href: "/diets/",
						name: "Diets",
					},
					{
						href: "/foods/",
						name: "Foods",
					},
					{
						href: "/other/",
						name: "Other",
					},
					{
						href: "/interventions/",
						name: "All interventions",
						isIndex: true,
					},
				],
			},
		],
		megamenu: true,
		access: ["free", "paid"],
	},
	{
		id: 5,
		title: "Supplements",
		href: "/interventions/",
		children: [
			{
				name: "Supplements",
				href: "/supplements/",
				children: [
					{
						href: "/supplements/ashwagandha/",
						name: "Ashwagandha",
					},
					{
						href: "/supplements/creatine/",
						name: "Creatine",
					},
					{
						href: "/supplements/curcumin/",
						name: "Curcumin",
					},
					{
						href: "/supplements/fish-oil/",
						name: "Fish Oil",
					},
					{
						href: "/supplements/vitamin-c/",
						name: "Vitamin C",
					},
					{
						href: "/supplements/vitamin-d/",
						name: "Vitamin D",
					},
					{
						href: "/supplements/",
						name: "More supplements",
						isIndex: true,
					},
				],
			},
			{
				name: "More Interventions",
				href: "/interventions/",
				children: [
					{
						href: "/diets/",
						name: "Diets",
					},
					{
						href: "/foods/",
						name: "Foods",
					},
					{
						href: "/other/",
						name: "Other",
					},
					{
						href: "/interventions/",
						name: "All interventions",
						isIndex: true,
					},
				],
			},
		],
		megamenu: true,
		access: ["logged-out"],
	},
	{
		id: 6,
		href: "/research-feed/",
		title: "Research Feed",
		children: [
			{
				href: "/research-feed/filter/?filter=categories&value=diets-foods",
				name: "Diet & Foods",
			},
			{
				href: "/research-feed/filter/?tag=editorspick",
				name: "Editor's Picks",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=healthy-aging-longevity",
				name: "Healthy Aging & Longevity",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=muscle-gain-exercise",
				name: "Muscle Gain & Exercise",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=sleep",
				name: "Sleep",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=vitamins-minerals",
				name: "Vitamins & Minerals",
			},
			{
				href: "/research-feed/",
				name: "view-all",
				isIndex: true,
			},
		],
		access: ["paid"],
	},
	{
		id: 7,
		href: "/guides/",
		title: "Guides",
		children: [
			{
				href: "/guides/fat-loss/",
				name: "Fat Loss",
			},
			{
				href: "/guides/libido/",
				name: "Libido & Sexual Function",
			},
			{
				href: "/guides/memory-focus/",
				name: "Memory & Focus",
			},
			{
				href: "/guides/mood-depression/",
				name: "Mood & Depression",
			},
			{
				href: "/guides/protein-intake/",
				name: "Protein Intake/Calculator",
			},
			{
				href: "/guides/testosterone/",
				name: "Testosterone",
			},
			{
				href: "/guides/",
				name: "view-all",
				isIndex: true,
			},
		],
		access: ["paid"],
	},
	{
		id: 8,
		href: "/plus/",
		title: "Examine+",
	},
	{
		id: 10,
		href: "/about/",
		title: "About",
		children: [
			{
				name: "About us",
				href: "/about/",
			},
			{
				name: "Latest site updates",
				href: "/updates/",
			},
			{
				name: "How to use Examine",
				href: "/how-to-use/",
			},
			{
				name: "Our editorial process",
				href: "/about/editorial-policy/",
			},
			{
				name: "Contact us",
				href: "/contact/",
			},
			{
				name: "Examine App",
				href: "/how-to-add-examine-to-home-screen/",
			},
		],
	},
];

export const navLinksMobile = [
	{
		id: 0,
		href: "/categories/",
		name: "Categories",
	},
	{
		id: 1,
		href: "/conditions/",
		name: "Conditions",
	},
	{
		id: 2,
		href: "/interventions/",
		name: "Supplements & Interventions",
		children: [
			{
				href: "/supplements/",
				name: "Supplements",
			},

			{
				href: "/diets/",
				name: "Diets",
			},
			{
				href: "/foods/",
				name: "Foods",
			},
			{
				href: "/other/",
				name: "Other",
			},
		],
	},
	{
		id: 3,
		href: "/plus/",
		name: "Examine+",
	},
	{
		id: 4,
		href: "/",
		name: "Free weekly insights",
		// name: 'Get Weekly Updates',
	},
	{
		id: 5,
		href: "/outcomes/",
		name: "Outcomes",
	},
	{
		id: 6,
		href: "/research-feed/",
		name: "Research Feed",
	},
	{
		id: 7,
		href: "/guides/",
		name: "Guides",
	},
	{
		id: 8,
		href: "/about/",
		name: "About Us",
	},
	{
		id: 9,
		href: "/members/dashboard/",
		name: "Member's Area",
	},
	{
		id: 10,
		href: "/how-to-add-examine-to-home-screen/",
		name: "Examine App",
	},
];

export const subscriberLinks = [
	{
		href: "/plus-info/",
		name: "Subscription Benefits",
	},
	{
		href: "/updates/",
		name: "Latest Updates",
	},
	{
		href: "/guides/",
		name: "Supplement Guides",
	},
	{
		href: "/research-feed/",
		name: "Research Feed",
	},
	{
		href: "/examine-ai/",
		name: "ExamineAI Beta",
	},
	{
		href: "/members/continuing-education/",
		name: "Continuing Education Credits",
	},
];

export const searchLinks = [
	{
		href: "/categories/",
		name: "Categories",
		children: [
			{
				href: "/categories/brain-health/",
				name: "Brain Health",
			},
			{
				href: "/categories/fat-loss/",
				name: "Fat Loss",
			},
			{
				href: "/categories/gut-health/",
				name: "Gut Health",
			},

			{
				href: "/categories/healthy-aging-longevity/",
				name: "Healthy Aging Longevity",
			},
			{
				href: "/categories/muscle-gain-exercise/",
				name: "Muscle Gain & Exercise",
			},
			{
				href: "/categories/sleep/",
				name: "Sleep",
			},
		],
	},
	{
		href: "/conditions/",
		name: "Conditions",
		children: [
			{
				href: "/conditions/anxiety/",
				name: "Anxiety",
			},

			{
				href: "/conditions/alzheimers-disease/",
				name: "Alzheimer’s Disease",
			},
			{
				href: "/conditions/coronary-artery-disease/",
				name: "Coronary Artery Disease",
			},
			{
				href: "/conditions/depression/",
				name: "Depression",
			},
			{
				href: "/conditions/insomnia/",
				name: "Insomnia",
			},
			{
				href: "/conditions/multiple-sclerosis/",
				name: "Multiple Sclerosis",
			},
		],
	},
	{
		href: "/interventions/",
		name: "Interventions",
		children: [
			{
				href: "/supplements/ashwagandha/",
				name: "Ashwagandha",
			},

			{
				href: "/supplements/creatine/",
				name: "Creatine",
			},
			{
				href: "/supplements/curcumin/",
				name: "Curcumin",
			},
			{
				href: "/supplements/fish-oil/",
				name: "Fish Oil",
			},
			{
				href: "/supplements/vitamin-c/",
				name: "Vitamin C",
			},
			{
				href: "/supplements/vitamin-d/",
				name: "Vitamin D",
			},
		],
	},
];

export const memberLinks = [
	{
		name: "My Research Feed",
		href: "/research-feed/",
	},
	{
		name: "Saved Pages",
		href: "/members/saved/",
	},
	{
		name: "My Notes",
		href: "/members/notes/",
	},
	{
		name: "My Profile",
		href: "/members/profile/",
	},
	{
		name: "Subscription & Purchases",
		href: "/members/membership/",
	},
	{
		name: "Receipts & Invoices",
		href: "/members/receipts/",
	},
	{
		name: "My Referrals",
		href: "/members/myreferrals/",
	},
];

export const companyMembersLink = {
	name: "Examine+ Enterprise Users",
	href: "/members/enterprise-users/",
};
