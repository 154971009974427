"use client";

import * as React from "react";
import { useSearchParams, useRouter } from "next/navigation";
import { BsSearch } from "react-icons/bs";
import { debounce } from "lodash-es";

import { cn } from "@/lib/utils";
import { SearchAutoCompleteDropdown } from "@/components/search/search-auto-complete";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useUI } from "@/components/ui/context";
import { Skeleton } from "@/components/ui/skeleton";

interface SearchInputProps extends React.HTMLAttributes<HTMLFormElement> {
	className?: string;
	placeholder?: string;
	home?: boolean;
	focusInput?: boolean;
	focusInputMobile?: boolean;
	setIsSearch?: React.Dispatch<React.SetStateAction<boolean>>;
	isMenu?: boolean;
}

const debouncedFetchAutoSuggest = debounce(fetchAutoSuggest, 250);

async function fetchAutoSuggest(value, setAutoSuggestResults, setShowSuggetion) {
	if (value !== "") {
		const res = await fetch(
			`${process.env.NEXT_PUBLIC_SEARCH_URL}/indexes/content/search?q=${value}`,
			{
				headers: {
					Authorization: `Bearer ${process.env.NEXT_PUBLIC_SEARCH_API_KEY}`,
				},
				next: { revalidate: 60 * 60 },
			}
		);
		if (!res.ok) {
			return;
		}

		const data = await res.json();

		setAutoSuggestResults(data.hits.length > 0 ? data.hits : null);
		setShowSuggetion(true);
	}
}

export default function SearchInput({
	className,
	placeholder = "What do you want to learn about?",
	home,
	focusInput,
	focusInputMobile,
	setIsSearch,
	isMenu,
	...props
}: SearchInputProps) {
	const searchParams = useSearchParams();
	const router = useRouter();
	const [searchTerm, setSearchTerm] = React.useState<any>(
		searchParams.has("q") ? searchParams.get("q") : ""
	);
	const [autoSuggestResults, setAutoSuggestResults] = React.useState([]);
	const [showSuggestion, setShowSuggetion] = React.useState(false);
	const [focusedIndex, setFocusedIndex] = React.useState(-1);
	const dropdownRef = React.useRef(null);
	const formRef = React.useRef(null);
	const { openSearchDialog, closeSearchDialog } = useUI();
	useOutsideAlerter(dropdownRef, setShowSuggetion);

	const autoSuggestHandler = async (e: { target: { value: any } }) => {
		const value = e.target.value;
		setSearchTerm(value);

		debouncedFetchAutoSuggest(value, setAutoSuggestResults, setShowSuggetion);
	};

	const handleOnSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		if (searchTerm) {
			if (home) {
				if (focusedIndex > 0) {
					const hitItem: any = autoSuggestResults[focusedIndex - 1];
					router.push(hitItem.url);
				} else {
					router.push(`/search/?q=${searchTerm.replace(/\s/g, "+")}`);
				}
			} else {
				router.push(`/search/?q=${searchTerm.replace(/\s/g, "+")}`);
			}

			window.dataLayer = window.dataLayer || [];
			window.dataLayer?.push({
				event: "search",
				search_term: searchTerm.toLowerCase(),
			});
		}
		closeSearchDialog();
		setShowSuggetion(false);
	};

	const handleSelection = (idx: number) => {
		const selected: any = autoSuggestResults[idx - 1];
		if (selected !== undefined) router.push(selected.url);
	};

	const handleKeyDown = (e: any) => {
		const { key } = e;
		let nextIndexCount = 0;

		// move down
		if (key === "ArrowDown") {
			e.preventDefault();
			nextIndexCount = (focusedIndex + 1) % 9;
		}

		// move up
		if (key === "ArrowUp") {
			e.preventDefault();
			nextIndexCount = (focusedIndex + 8) % 9;
		}

		// hide search results
		if (key === "Escape") {
			e.preventDefault();
			setShowSuggetion(false);
		}

		// select the current item
		if (key === "Return" || key === "Go") {
			e.preventDefault();
			handleSelection(focusedIndex);
		}

		if (key == "Enter") {
			e.preventDefault();
			handleOnSubmit(e);
		}

		setFocusedIndex(nextIndexCount);
	};

	const onFocusHandler = () => {
		if (window.innerWidth < 767) {
			openSearchDialog();
			const header: any = document.querySelector("header")?.clientHeight;
			const y = header + 30;

			if (!isMenu) {
				window.scrollTo(0, y);
			}
		}
	};

	React.useEffect(() => {
		if (searchParams.has("q")) {
			setSearchTerm(searchParams.get("q"));
		}
	}, [searchParams]);

	const handleFocus = (input: any) => {
		if (focusInputMobile && input && window.innerWidth < 640) {
			input.focus();
			return;
		}

		if (focusInput && input && window.innerWidth > 640) {
			input.focus();
			return;
		}
		return;
	};

	return (
		<>
			<div
				ref={formRef}
				className={cn("relative mt-3", className)}
				tabIndex={0}
				onKeyDown={handleKeyDown}
			>
				<form
					id="search"
					className={cn(
						"relative border-b -tracking-2",
						showSuggestion ? "rounded-t-md border-gray-300" : "rounded border-transparent"
					)}
					onSubmit={handleOnSubmit}
					action="true"
				>
					<Input
						ref={(input) => handleFocus(input)}
						type="search"
						value={searchTerm}
						onChange={autoSuggestHandler}
						onClick={onFocusHandler}
						maxLength={500}
						className="w-full border px-3 py-2.5 pr-12 text-black shadow-none lg:px-5 lg:py-[15px] lg:pr-20"
						placeholder={placeholder}
					/>

					<Button
						type="submit"
						className={cn(
							"absolute right-0 top-0 h-full cursor-pointer rounded-l-none px-3 hover:bg-examine-purple-400 lg:px-6",
							home ? "bg-primary" : "bg-hover"
						)}
						role="button"
						aria-label="Search"
					>
						<BsSearch className="size-5 fill-white lg:size-6" />
					</Button>
				</form>

				<SearchAutoCompleteDropdown
					data={autoSuggestResults}
					handleSelection={handleSelection}
					{...{
						searchTerm,
						showSuggestion,
						dropdownRef,
						focusedIndex,
						setIsSearch,
					}}
				/>
			</div>
		</>
	);
}

function useOutsideAlerter(ref: any, setShowSuggetion: any) {
	React.useEffect(() => {
		function handleClickOutside(event: { target: any }) {
			if (window.innerWidth < 767) return;

			const exemptingElements = ["gpt-access-dialog"];

			const isChildOfExemptingElement = (element: HTMLElement | null): boolean => {
				if (!element) return false;
				if (exemptingElements.includes(element.id)) return true;
				return isChildOfExemptingElement(element.parentElement);
			};

			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				!exemptingElements.includes(event.target.id) &&
				!isChildOfExemptingElement(event.target)
			) {
				setShowSuggetion(false);
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, setShowSuggetion]);
}

SearchInput.Skeleton = function SearchInputFallback() {
	return (
		<div className="grid w-full gap-10">
			<Skeleton className="h-14 w-full" />
		</div>
	);
};
