"use client";

import React from "react";

import { differenceInHours } from "date-fns";
import { useSettings } from "@/lib/hooks/use-settings";

interface EndsTonightProps extends React.HTMLAttributes<HTMLDivElement> {
	text?: string | React.ReactNode;
	ctaText?: string | React.ReactNode;
	dayBeforeCta?: string | React.ReactNode;
	lastDay?: string | React.ReactNode;
}

export default function EndsTonight({ children, dayBeforeCta, lastDay }: EndsTonightProps) {
	const { settings } = useSettings();
	const hours = differenceInHours(
		new Date(settings?.end * 1000 || "2024-08-16T12:00:00-04:00"),
		new Date()
	);

	return hours < 24 ? lastDay : hours < 48 ? dayBeforeCta || "ENDS TONIGHT" : children;
}
