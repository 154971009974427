"use client";

import * as React from "react";
import Link from "next/link";

import { SubscriptionInfo, User as UserType, Feature, Settings } from "@/types";
import { cn } from "@/lib/utils";
import { useUser } from "@/lib/hooks/auth";
import { useCompanyDetails } from "@/lib/hooks/use-company";
import { useEnterprise } from "@/lib/hooks/use-enterprise";
import { companyMembersLink, navLinks, subscriberLinks } from "@/lib/data/navigation";
import { ArrowRightIcon } from "lucide-react";
import { LoginLink } from "@/components/login-link";
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuIndicator,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Skeleton } from "@/components/ui/skeleton";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import { Badge } from "@/components/ui/badge";
import UserIcon from "@/components/icons/user";
import SearchIcon from "@/components/icons/search-icon";
import SearchDialog from "@/components/search/search-dialog";
import EndsTonight from "@/components/ends-tonight";
import SaleWrapper from "@/components/sale-wrapper";
import { ExaminePlusLink } from "@/components/cta";
import { useSWRConfig } from "swr";

const ListLinks = React.forwardRef<
	React.ElementRef<"a">,
	React.ComponentPropsWithoutRef<"a"> & { isIndex?: boolean; children?: React.ReactNode }
>(({ className, href, title, isIndex, children, ...props }, ref) => {
	if (isIndex) {
		return (
			<li>
				<NavigationMenuLink asChild>
					{
						<a
							ref={ref}
							href={href}
							className={cn(
								"flex items-center space-x-3 whitespace-nowrap p-2 font-semibold text-gray-900 hover:text-primary lg:hover:bg-gray-100",
								className
							)}
						>
							{children ? children : title === "view-all" ? "View all" : title}
							<ArrowRightIcon size={16} />
						</a>
					}
				</NavigationMenuLink>
			</li>
		);
	}
	return (
		<li>
			<NavigationMenuLink asChild>
				{
					<a
						ref={ref}
						href={href}
						className={cn(
							"block whitespace-nowrap rounded p-2 text-gray-900 hover:text-primary lg:hover:bg-gray-100",
							className
						)}
					>
						{children ? children : title}
					</a>
				}
			</NavigationMenuLink>
		</li>
	);
});

ListLinks.displayName = "ListLinks";

const MegaMenu = ({ title, href, sublinks }: any) => {
	return (
		<li>
			<Link href={href} className="text-extra-gray whitespace-nowrap font-lora text-2xl font-bold">
				{title}
			</Link>
			<ul className="mt-4 space-y-1.5">
				{sublinks.map((link: any, index) => {
					return (
						<ListLinks key={link.href} href={link.href} title={link.name} isIndex={link.isIndex} />
					);
				})}
			</ul>
		</li>
	);
};

interface ExaminePlusProps {
	subscription: SubscriptionInfo;
	gptAccess?: boolean | number;
}

const ExaminePlus = ({ subscription, gptAccess }: ExaminePlusProps) => {
	if (subscription?.is_active) {
		return (
			<NavigationMenu>
				<NavigationMenuList>
					<NavigationMenuItem>
						<NavigationMenuTrigger className="rounded-full bg-premium px-3 py-1.5 font-bold text-primary decoration-2 transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2">
							<Link href="/plus-info/" className="">
								Examine+
							</Link>
						</NavigationMenuTrigger>
						<NavigationMenuContent className=" bg-white">
							<ul className="p-4">
								{subscriberLinks
									.filter((link) => link.href !== "/examine-ai/" || gptAccess)
									.map((link: { href: string; name: string; isIndex: boolean }) => {
										if (link.href === "/examine-ai/") {
											return (
												<ListLinks
													key={link.href}
													href={link.href}
													title={link.name}
													isIndex={link.isIndex}
												>
													<span>
														ExamineAI
														<span className="px-0.5 align-super text-xs font-semibold text-red-400">
															BETA
														</span>
													</span>
												</ListLinks>
											);
										}
										return (
											<ListLinks
												key={link.href}
												href={link.href}
												title={link.name}
												isIndex={link.isIndex}
											/>
										);
									})}
							</ul>
						</NavigationMenuContent>
					</NavigationMenuItem>
					<NavigationMenuIndicator />
				</NavigationMenuList>
			</NavigationMenu>
		);
	}

	return (
		<Tooltip>
			<TooltipTrigger>
				<SaleWrapper
					cta={
						<FreeTrialWrapper
							cta={
								<Link
									href="/plus/"
									className="mx-2.5 flex items-center space-x-2 whitespace-nowrap rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2"
								>
									Examine+
								</Link>
							}
							fallback={<Skeleton className="mx-2.5 h-[36px] w-[200px] rounded-full" />}
						>
							<ExaminePlusLink trackDetails={{ trackFor: "trial", label: "Try Examine+ for free" }}>
								<span className="mx-2.5 flex items-center space-x-2 whitespace-nowrap rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2">
									Try Examine+ for free
								</span>
							</ExaminePlusLink>
						</FreeTrialWrapper>
					}
				>
					<ExaminePlusLink trackDetails={{ trackFor: "sale", label: "Examine+ ON SALE" }}>
						<span className="mx-2.5 flex items-center space-x-2 whitespace-nowrap rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2">
							Examine+
							<Badge variant="cyan" className="ml-1.5">
								<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
									ON SALE
								</EndsTonight>
							</Badge>
						</span>
					</ExaminePlusLink>
				</SaleWrapper>
			</TooltipTrigger>
			<TooltipContent className="z-1000">
				<p className="!bg-examine-yellow-premium !font-medium !leading-6 !text-examine-purple-700">
					Examine is entirely self-funded, with no third-party ads or ties to food and supplement
					companies.{" "}
					<span className="font-semibold">
						Examine+ unlocks the Examine Database, Study Summaries, and Supplement Guides.
					</span>
				</p>
			</TooltipContent>
		</Tooltip>
	);
};

interface UserProps {
	user: UserType;
	subscription: SubscriptionInfo;
}

const User = ({ user, subscription }: UserProps) => {
	const { isEnterpriseUser, enterpriseName } = useEnterprise();
	const { isAdmin } = useCompanyDetails();

	if (isEnterpriseUser) {
		window.dataLayer = window?.dataLayer || [];
		window.dataLayer?.push({
			"dlv-enterprise_name": enterpriseName,
		});
	}

	if (user) {
		return (
			<>
				<About />
				<NavigationMenu className="last:[&>.viewport]:right-0">
					<NavigationMenuList>
						<NavigationMenuItem>
							<NavigationMenuTrigger>
								<Link href="/members/profile/">
									<UserIcon />
								</Link>
							</NavigationMenuTrigger>
							<NavigationMenuContent className="bg-white">
								<div className="space-y-1.5 px-6 py-4">
									<p className="whitespace-nowrap font-bold text-gray-900">{user?.full_name}</p>
									{subscription?.is_active && (
										<p className="text-sm text-gray-600">{subscription?.name}</p>
									)}
								</div>
								<hr className="mx-6 border-gray-300" />
								<ul className="p-4">
									<ListLinks href="/research-feed/" title="My Research Feed" />
									<ListLinks href="/members/saved/" title="Saved Pages" />
									<ListLinks href="/members/notes/" title="My Notes" />
									<ListLinks href="/members/profile/" title="My Profile" />
									<ListLinks href="/members/membership/" title="Subscription & Purchases" />
									<ListLinks href="/members/receipts/" title="Receipts & Invoices" />
									{!isEnterpriseUser && (
										<ListLinks href="/members/myreferrals/" title="My Referrals" />
									)}
									{isAdmin && (
										<ListLinks href="/members/enterprise-users/" title={companyMembersLink.name} />
									)}
									<ListLinks href="/api/logout/" title="Logout" />
								</ul>
							</NavigationMenuContent>
						</NavigationMenuItem>
						<NavigationMenuIndicator />
					</NavigationMenuList>
				</NavigationMenu>
			</>
		);
	}

	return (
		<>
			<LoginLink className="px-3 py-2 text-gray-900 hover:text-primary">Login</LoginLink>
			<About />
		</>
	);
};

const About = () => {
	const about = navLinks.find((link) => link.href === "/about/");
	if (!about) return null;
	return (
		<NavigationMenu aria-label="About" className="last:[&>.viewport]:right-0">
			<NavigationMenuList>
				<NavigationMenuItem value="/about/">
					<NavigationMenuTrigger>
						<Link href={about.href}>{about.title}</Link>
					</NavigationMenuTrigger>
					<NavigationMenuContent className="bg-white">
						<ul className="p-4">
							{about.children?.map((child: any) => (
								<ListLinks key={child.href} href={child.href} title={child.name} />
							))}
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
				<NavigationMenuIndicator />
			</NavigationMenuList>
		</NavigationMenu>
	);
};

interface NavigationClickInterface {
	access: string;
	text?: string;
	link?: string;
}

const trackNavigationClick = ({ access, text, link }: NavigationClickInterface) => {
	window.dataLayer?.push({
		event: "navigation_click",
		text,
		link,
	});
};

interface NavigationProps extends React.HTMLAttributes<HTMLDivElement> {
	user: UserType;
	subscription?: SubscriptionInfo;
}

const Navigation = ({ user, subscription, className, ...props }: NavigationProps) => {
	const access = user && !subscription ? "free" : user && subscription ? "paid" : "logged-out";
	const gptAccess = user?.gpt;

	const menuRef = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		const handleLinkClick = (event) => {
			const target = event.target.closest("a, .search");

			if (target) {
				trackNavigationClick({
					access,
					text: event.target.innerText,
					link: target.href,
				});
			}
		};

		menuRef.current?.addEventListener("click", handleLinkClick);

		return () => {
			menuRef.current?.removeEventListener("click", handleLinkClick);
		};
	}, [access]);

	return (
		<>
			<div
				{...props}
				className={cn("hidden items-center text-sm lg:flex xl:text-menu", className)}
				aria-label="Main Menu"
			>
				<SearchDialog {...{ subscription }}>
					<div className="search flex items-center gap-x-2 px-3 py-2 font-medium text-primary hover:text-gray-900">
						<span>Search</span>
						<SearchIcon className="h-4 w-4" />
					</div>
				</SearchDialog>
				<div ref={menuRef} className="flex items-center pr-8 text-sm xl:text-menu">
					{navLinks?.map((link: any) => {
						if (link?.access && !link?.access?.includes(access)) {
							return null;
						}

						if (link.href === "/plus/") {
							return (
								<ExaminePlus key={link.id} subscription={subscription} gptAccess={gptAccess} />
							);
						}

						if (link.href === "/about/") {
							return <User key={link.id} user={user} subscription={subscription} />;
						}

						if (!link?.children) {
							return (
								<Link
									key={link.id}
									href={link.href}
									className="px-3 py-2 text-gray-900 hover:text-primary"
								>
									{link.title}
								</Link>
							);
						}

						return (
							<NavigationMenu key={link.id} aria-label={link.title}>
								<NavigationMenuList>
									<NavigationMenuItem value={link.href}>
										<NavigationMenuTrigger>
											<Link href={link.href}>{link.title}</Link>
										</NavigationMenuTrigger>
										<NavigationMenuContent>
											{link.megamenu ? (
												<ul className="flex gap-12 p-10">
													{link.children.map((section: any) => (
														<MegaMenu
															key={section.name}
															title={section.name}
															href={section.href}
															sublinks={section.children}
														/>
													))}
												</ul>
											) : (
												<ul className="p-4">
													{link.children?.map((child: any) => (
														<ListLinks
															key={child.href}
															href={child.href}
															title={child.name}
															isIndex={child.isIndex}
														/>
													))}
												</ul>
											)}
										</NavigationMenuContent>
									</NavigationMenuItem>
									<NavigationMenuIndicator />
								</NavigationMenuList>
							</NavigationMenu>
						);
					})}
				</div>
			</div>

			<div className="flex items-center lg:hidden">
				<LoginLink
					href={user ? "/members/profile/" : null}
					className="flex flex-1 flex-row-reverse items-center gap-1 text-white lg:hidden"
				>
					<span className="max-w-20 overflow-hidden text-ellipsis whitespace-pre">
						{user ? user?.first_name : "Login"}
					</span>
					<UserIcon className="text-white" />
				</LoginLink>
				<SearchDialog {...{ subscription }}>
					<div className="search flex items-center gap-x-2 px-3 py-2 font-medium text-primary hover:text-gray-900">
						<span className="hidden">Search</span>
						<SearchIcon className="h-4 w-4" color="#fff" />
					</div>
				</SearchDialog>
			</div>
		</>
	);
};

export function NavigationSkeleton() {
	return (
		<div className="lg:w-3/6">
			<div className="hidden grid-cols-6 gap-x-6 pr-11 lg:grid">
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
			</div>

			<div className="grid grid-cols-7 items-center justify-end gap-1 lg:hidden">
				<Skeleton className="col-span-2 h-6 w-6 place-self-center rounded-full" />
				<Skeleton className="col-span-3 h-4 w-12 place-self-center rounded" />
				<Skeleton className="col-span-2 h-5 w-5 place-self-center rounded-full" />
			</div>
		</div>
	);
}
export default Navigation;
