"use client";

import { useTimer } from "react-timer-hook";
import { useSettings } from "@/lib/hooks/use-settings";
import { ClientOnly } from "@/components/client-only";

interface CountDownTimerProps {
	expiryTimestamp?: Date;
}

export default function CountDownTimer({ expiryTimestamp }: CountDownTimerProps) {
	const { settings } = useSettings();
	const salesEnd: Date = new Date(settings?.end * 1000 || "2024-08-16T12:00:00-04:00");

	const { seconds, minutes, hours, days } = useTimer({
		expiryTimestamp: expiryTimestamp || salesEnd,
		autoStart: true,
	});

	if (!seconds) {
		return null;
	}

	return (
		<ClientOnly>
			<div className="ml-1 inline-block font-bold">
				Time left: <span>{days.toString().padStart(2, "0")}</span>:
				<span>{hours.toString().padStart(2, "0")}</span>:
				<span>{minutes.toString().padStart(2, "0")}</span>:
				<span>{seconds.toString().padStart(2, "0")}</span>
			</div>
		</ClientOnly>
	);
}
